import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);
const PLUGIN_URL = "/api/engineName";
const BrowserEngine = () => {
  const [Engines, setEngines] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(PLUGIN_URL, {
        params: {
          token: searchParams.get("token"),
          date: searchParams.get("date"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setEngines(response.data);
        setLoading(false);
      })
      .catch(function (err) {});
  }, []);
  const copyItems = [];
  const Visit = [];
  // before
  for (let i = 0; i < Engines.length; i++) {
    copyItems.push(Engines[i]["name"]);
    Visit.push(Engines[i]["visit"]);
  }
  const data = {
    labels: copyItems,
    datasets: [
      {
        label: " No of Visit",
        data: Visit,
        backgroundColor: [
          "#8db9fa",
          "#d43617",
          "#c717d4",
          "#d49c17",
          "#17d463",
          "#c9e919",
        ],
        borderColor: ["white"],
        borderWidth: 0.5,
      },
    ],
  };
  const options = {
    responsive: true,
  };
  return (
    <div className="Device">
      <div className="Card">
        <div className="Device-Title">
          <h3>Browser Engine</h3>
        </div>

        <div className="data-container">
          <div className="data">
            <Pie data={data} className="piechart" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowserEngine;
