import React, { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import { NavLink, useNavigate } from "react-router-dom";
const REGISTER_URL = "/api/registration";

const Register = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const userRef = useRef();
  const navigation = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const SetNull = () => {
    setError("");
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!firstname && !lastname && !email && !password && !confirmpassword) {
      setError("Please enter required information");
      return false;
    } else if (!firstname) {
      setError("Please enter your first name");
      return false;
    } else if (!lastname) {
      setError("Please enter your last name");
      return false;
    } else if (!email) {
      setError("Please enter your email");
      return false;
    } else if (!password) {
      setError("Please enter a password");
      return false;
    } else if (!confirmpassword) {
      setError("Please confirm your password");
      return false;
    } else if (password !== confirmpassword) {
      setError("Passwords do not match");
      return false;
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return false;
    } else {
      return true;
    }
  };

  const checkPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 6) {
      strength += 20;
    }
    if (/\d/.test(password)) {
      strength += 20;
    }
    if (/[a-z]/.test(password)) {
      strength += 20;
    }
    if (/[A-Z]/.test(password)) {
      strength += 20;
    }
    if (/[\W_]/.test(password)) {
      strength += 20;
    }
    return strength;
  };
  //
  const register = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          firstname: firstname,
          lastname: lastname,
          password: password,
          email: email
        }),
        {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true,
        }
      );

      const data = {
        id: response.data.id,
        name: response.data.username,
        // token: response.data.token
      };
      const datas = new URLSearchParams(data).toString();
      if (response.data.status === 200) {
        navigation(`/Addsite?${datas}`);
      } else if (response.data.status === 400) {
        setError("Email already exists");
      } else {
        setError("Registration Failed");
      }
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (err) {
      setError("No Server Response");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordStrength(checkPasswordStrength(e.target.value));
  };

  return (
    <section className="try">
      <div className="Back-login"> </div>
      <div className="login">
        <div className="login-contents">
          <div className="login-cont">
            <div>
              <NavLink className="logo" to="/">
                <img
                  src={require(`../Assets/icons/icon.png`)}
                  alt="Tracker Logo"
                />
                <h1>Tracker</h1>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="form-reg-cont">
            <div>
              {error && (
                <span className="errmsg" aria-live="assertive">
                  {error}
                </span>
              )}
              <br />
              <input
                type="text"
                name="firstname"
                autoComplete="off"
                value={firstname}
                ref={userRef}
                onChange={(e) => setFirstName(e.target.value)}
                onFocus={SetNull}
                placeholder="First Name"
              />
              <input
                type="text"
                name="lastname"
                autoComplete="off"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
                onFocus={SetNull}
                placeholder="Last Name"
              />
              <input
                type="email"
                name="email"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={SetNull}
                placeholder="Email"
              />
              <input
                type="password"
                name="password"
                autoComplete="off"
                value={password}
                onChange={handlePasswordChange}
                onFocus={SetNull}
                placeholder="Password"
              />
              <div className="password-progress">
                <progress value={passwordStrength} max="100"></progress>
                <p>&nbsp; password strength </p>
              </div>
              <input
                type="password"
                name="confirmpassword"
                autoComplete="off"
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onFocus={SetNull}
                placeholder="Confirm Password"
              />
              <br />
              <button className="btn-login" onClick={register}>
                Register
              </button>
            </div>
            <div className="usr">
              <h1>
                Already have an account?{" "}
                <NavLink className="forget" to="/Login">
                  Login
                </NavLink>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
