import React from 'react'

const Testimonial = () => {
    return (
      <section>
        <div className="testimonial_header">
          <h1>Testimonials of our customers</h1>
          <figure class="customer_tetsimonial">
            <figcaption>
              <blockquote>
                <p>
                I've been using Liyu Analytics for almost a week and 
                I'm blown away by the insights it provides. It's helped 
                me understand how Analytics can improve a business and give 
                a better insight of users and site performance. <br />
                <b>Highly recommend!</b> 
                </p>
              </blockquote>
              <h3><b>Biniyam Zeleke</b></h3>
              <h4>Lewach Items Exchange</h4>
            </figcaption>
          </figure>
          <figure class="customer_tetsimonial">
            <figcaption>
              <blockquote>
                <p>Liyu Analytics is a must-have for any 
                  business owner in Ethiopia who wants to improve their online 
                  presence. It's totally free, and provides valuable 
                  insights on website traffic, user behavior, and more. I highly 
                  recommend it to anyone looking 
                  to grow their business."</p>
              </blockquote>
              <h3><b>Andualem Nigatu</b></h3>
              <h4>Senior Graphic Designer</h4>
            </figcaption>
          </figure>
          <figure class="customer_tetsimonial">
            <figcaption>
              <blockquote>
                <p>
                Ethiopian based web analytics system is a great option for businesses 
                and individuals who value data privacy and security. With this Ethiopian 
                based web analytics system, you can be sure that your website data is 
                being handled locally by a local team.
                </p>
              </blockquote>
              <h3><b>Natnael Tilaye</b></h3>
              <h4>CEO, Earald Trading</h4>
            </figcaption>
          </figure>
        </div>
      </section>
    );
  }

  export default Testimonial;