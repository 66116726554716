import React, { useEffect, useState } from "react";
import Preloader from "../inc/Preloader";
import Browser from "../components/Software/Browser";
import BrowserEngine from "../components/Software/BrowserEngine";
import OperatingSystem from "../components/Software/OperatingSystem";
import Plugin from "../components/Software/Plugin";
const Software = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {loading ? (
        <div className="preloader">
          <Preloader />
        </div>
      ) : (
        <div className="card-cont">
          <div className="one">
            <Browser />
          </div>
          <div className="one">
            <OperatingSystem />
          </div>
          <div className="one">
            <BrowserEngine />
          </div>
          <div className="one">
            <Plugin />
          </div>
        </div>
      )}
    </>
  );
};

export default Software;
