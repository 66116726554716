import React from "react";
const Discription = () => {
  return (
    <section className="Tracker-disc">
         <div className="why-tracker">
         <div class="services-card row_one">
         <div className="box ">
        <h2>Web traffic in Real-time </h2>
        {/* <br /> */}
        <p>
        Track and analyse 
        real-time data on a website visitors, including 
        their location, device, and browsing behavior.
        </p>
      </div>
  <div class="glow" />
</div>
<div class="services-card row_one">
<div className="box ">
        <h2>Traffic source analysis</h2>
        {/* <br /> */}
        <p>
        Liyu analytics can help you 
        track where your website traffic is 
        emerging from, whether it's from search 
        engines, social media, or other sources.
        </p>
      </div>
  <div class="glow" />
</div>
<div class="services-card row_one">
   <div className="box ">
        <h2> Performance analysis </h2>
        {/* <br /> */}
        <p>
        measure the performance of your website content, 
        such as which pages or blog posts are most popular and engaging with visitors.
        </p>
      </div>
  <div class="glow" />
</div>
<div class="services-card row_two">
<div className="box ">
        <h2>Optimality</h2>
        {/* <br /> */}
        <p>
          Optimize your website based on your users Location, Device, Operating System, Language and other performance metrics.
        </p>
      </div>
  <div class="glow" />
</div>
<div class="services-card row_two">
   <div className="box ">
        <h2>Monitor changes</h2>
        {/* <br /> */}
        <p>
          The more profitable your project is, the more expensive each error
          costs. Monitor website changes 24/7 to keep your business running.
        </p>
      </div>
  <div class="glow" />
</div>
<div class="services-card row_two">
<div className="box ">
        <h2>Uncover journeys</h2>
        {/* <br /> */}
        <p>
          Explore user journeys and pinpoint platforms that drive the most
          website traffic with real user data.
        </p>
      </div>
  <div class="glow" />
</div>
      
     
      
     
      </div>
    </section>
  );
};

export default Discription;
