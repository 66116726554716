import React, { useEffect, useState } from "react";
import "../style/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrip,
  faCaretRight,
  faMagnifyingGlass,
  faMoon,
  faSun,
  faUserNinja,
  faFileContract,
  faDesktop,
  faEarthAfrica,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { faSnowflake } from "@fortawesome/free-regular-svg-icons";

import { NavLink } from "react-router-dom";
const Sidebar = ({ children }) => {
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [date, setDate] = useState("");
  const [id, setId] = useState();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setId(searchParams.get("id"));
    setName(searchParams.get("name"));
    setDate(searchParams.get("date"));
    setToken(searchParams.get("token"));
  }, []);

  function handler() {
    const sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
  }
  function search() {
    const sidebar = document.querySelector(".sidebar");
    sidebar.classList.remove("close");
  }
  function LightMood() {
    // const modeSwitch = document.querySelector(".toggle-switch");
    const body = document.querySelector(".Sidebar-body");
    body.classList.toggle("dark");
    const modeText = document.querySelector(".mode-text");
    if (body.classList.contains("dark")) {
      modeText.innerText = "Dark mode";
    } else {
      modeText.innerText = "Light mode";
    }
  }
  const data = {
    token: token,
    name: name,
    id: id,
    date: date
  };
  const datas = new URLSearchParams(data).toString();
  const links = [
    {
      path: `/?${datas}`,
      name: "Dashboard",
      icon: <FontAwesomeIcon icon={faGrip} />,
    },
    {
      path: `/Visitor?${datas}`,
      name: "Visitor",
      icon: <FontAwesomeIcon icon={faUserNinja} />,
    },
    {
      path: `/PageView?${datas}`,
      name: "PageView",
      icon: <FontAwesomeIcon icon={faFileContract} />,
    },
    {
      path: `/Device?${datas}`,
      name: "Device",
      icon: <FontAwesomeIcon icon={faDesktop} />,
    },
    {
      path: `/Software?${datas}`,
      name: "Software",
      icon: <FontAwesomeIcon icon={faSnowflake} />,
    },
    {
      path: `/Location?${datas}`,
      name: "Location",
      icon: <FontAwesomeIcon icon={faEarthAfrica} />,
    },
    {
      path: `/Settings?${datas}`,
      name: "Settings",
      icon: <FontAwesomeIcon icon={faGear} />,
    },
  ];
  return (
    <div className="Analytics-Dashboard">
      <div className="Sidebar-body">
        <div className="sidebar">
          <header>
            <div className="image-text">
              <span className="image">{/* <img src="logo.png" alt=""> */}</span>
              <div className="text logo-text">
                <span className="name">Liyu</span>
                <span className="profession">{name ? name : "ERP System"}</span>
              </div>
            </div>
            <span>
              <FontAwesomeIcon
                className="toggle"
                icon={faCaretRight}
                onClick={handler}
              />
            </span>
          </header>
          <div className="menu-bar">
            <div className="menu">
              <li className="search-box" onClick={search}>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
                <input type="text" placeholder="search..." />
              </li>
              <ul className="menu-links">
                {links.map((link) => (
                  <li className="nav-link">
                    <NavLink to={link.path}>
                      <span className="icon">{link.icon}</span>
                      <span className="text nav-text">{link.name}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bottom-content">
              {/* <li className="">
                <a href="#">
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="icon"
                  />
                  <span className="text nav-text">Logout</span>
                </a>
              </li> */}
              <li className="mode">
                <div className="sun-moon">
                  <FontAwesomeIcon icon={faMoon} className="icon moon" />
                  <FontAwesomeIcon icon={faSun} className="icon sun" />
                </div>
                <span className="mode-text text">Light mode</span>
                <div className="toggle-switch">
                  <span className="switch" onClick={LightMood}></span>
                </div>
              </li>
            </div>
          </div>
        </div>
        <div className="home">{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
