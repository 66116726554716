import React, { useEffect, useState } from "react";
import "../AnalyticsDashboard/style/style.css";
import "../../src/Assets/images/map-background.svg";
import axios from "../api/axios";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DOMAINREGISTER_URL = "/api/domainReg";
function Form() {
  const [websiteName, setWebsiteName] = useState("");
  const [url, setUrl] = useState("");
  const [userName, setuserName] = useState("");
  const [id, setId] = useState();
  const [token, setToken] = useState();
  const [error, setError] = useState("");
  const [check, setcheck] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = (event) => {
    event.preventDefault();
    const toryElement = document.querySelector(".IntegratedCode");
    if (toryElement) {
      const range = document.createRange();
      range.selectNode(toryElement);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      setIsCopied(true);
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setId(searchParams.get("id"));
    setToken(searchParams.get("token"));
  }, []);
  var Datas = "";
  if (check) {
    Datas = "";
  } else {
    Datas = (
      <div className="tracking-code">
        <div className="done_checkmark">
          <FontAwesomeIcon icon={faCircleCheck} />
          <h5>&nbsp; Addsite Completed</h5>
        </div>
        <div className="Copy_button">
          <button onClick={handleCopyClick}>
            {isCopied ? "Copied!" : "Copy"}
          </button>
        </div>
        <br />
        <div className="IntegratedCode">
          <pre>
            {"<script>"}
            (function () <br />
            {
              <code>
                {""}
                {"{"}
                var u = "https://trackerapi.earaldtradinget.com/";
                <br />
                var d = document,
                <br />
                g = d.createElement("script"),
                <br />
                s = d.getElementsByTagName("script")[0];
                <br />
                g.async = true; g.src = u + "Analytic.js";
                <br />
                s.parentNode.insertBefore(g, s);
                <br />
                {"}"}
              </code>
            }
            )();
            {"</script>"}
          </pre>
        </div>
      </div>
    );
  }
  // We must valiadte the domain url using regular expression
  const finished = () => {
    const data = {
      id: id,
      name: userName,
      token: token,
    };
    const datas = new URLSearchParams(data).toString();
    // window.location.href = `http://dashboard.localhost:3000/?${datas}`;
    window.location.href = `https://dashboard.earaldtradinget.com/?${datas}`;
  };
  const validateForm = () => {
    if (!websiteName && !url) {
      setError("Please enter required information");
      return false;
    } else if (!websiteName) {
      setError("Please Enter Your Website Name");
      return false;
    } else if (!url) {
      setError("Please Enter Your Domain Url");
      return false;
    } else {
      return true;
    }
  };
  const registerDomain = async (e) => {
    e.preventDefault();
    // setcheck(false);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(
        DOMAINREGISTER_URL,
        JSON.stringify({
          id: id,
          website_name: websiteName,
          url: url,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.status === 200) {
        setId(response.data.id);
        setuserName(response.data.username);
        setcheck(false);
        // setDomainName("");
        setWebsiteName("");
        setUrl("");
      } else {
        setError("Domain Registration Failed");
      }
    } catch (err) {
      setError("No Server Response");
    }
  };

  return (
    <section className="Addsite">
      <div className="Addsite__Body">
        <div className="form">
          <fieldset>
            <legend>
              <b>Add Your Website Info</b>
            </legend>
            {error && (
              <span className="errmsg" aria-live="assertive">
                {error}
              </span>
            )}
            <br />
            <label>Website Name:</label>
            <input
              type="text"
              name="websiteName"
              value={websiteName}
              onChange={(e) => setWebsiteName(e.target.value)}
            />
            <hr />
            <br />
            <p>
              <b>Note:</b> Enter a unique identifier used to identify your
              website to be tracked
            </p>
            <br />
            <label>URL:</label>
            <br />
            <input
              type="text"
              name="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <hr />
            <br />
            <p>
              <b>Note:</b> Enter a unique identifier that is used to locate and
              access a your website on the internet. It typically consists of
              two parts: the actual name and the top-level domain (TLD). For
              example, in the website name "google.com", "google" is the name
              and ".com" is the TLD.
            </p>
          </fieldset>
          {Datas}
          {check ? (
            <button className="Addsite__Button" onClick={registerDomain}>
              Store
            </button>
          ) : (
            <button className="Addsite__Button" onClick={finished}>
              Finish
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export default Form;
