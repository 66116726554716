import React, { useEffect, useState } from "react";
import Preloader from "../inc/Preloader";
import DeviceBrand from "../components/Device/DeviceBrand";
import DeviceModel from "../components/Device/DeviceModel";
import DeviceType from "../components/Device/DeviceType";
import DeviceScreenResolution from "../components/Device/DeviceScreenResolution";
const Device = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {loading ? (
        <div className="preloader">
          <Preloader />
        </div>
      ) : (
        <div className="card-cont">
          <div className="one">
            <DeviceType />
          </div>
          <div className="one">
            <DeviceModel />
          </div>
          <div className="one">
            <DeviceBrand />
          </div>
          <div className="one">
            <DeviceScreenResolution />
          </div>
        </div>
      )}
    </>
  );
};

export default Device;
