import React, { useEffect, useState } from "react";
import TotalCards from "../components/Dashboard/TotalCards";
import RealTime from "../components/Dashboard/RealTime";
import PopularPages from "../components/Dashboard/PopularPages";
import Preloader from "../inc/Preloader";
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {loading ? (
        <div className="preloader">
          <Preloader />
        </div>
      ) : (
        <>
          <TotalCards />
          <div className="RealTimeData">
            <RealTime />
            <PopularPages />
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
