import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { PaginationControl } from "react-bootstrap-pagination-control";
const VISITORLOG_URL = "/api/VisitorLog";
const VisitorLog = () => {
  const [visitorLog, setvisitorLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(VISITORLOG_URL, {
        params: {
          token: searchParams.get("token"),
          date: searchParams.get("date"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setvisitorLog(response.data);
        setLoading(false);
      })
      .catch(function (err) {});
  }, []);
  var Datas = "";
  if (loading) {
    Datas = <snap>Loading...</snap>;
  } else {
    Datas = visitorLog.map((item) => {
      return (
        <div className="containerVisitorLog">
          <div className="visitor-information">
            <h5>Visitor Device Id: {item.visitorDeviceID}</h5>
            <h5>Ip: {item.ip}</h5>
            <h5>Referrers: {item.Referrer}</h5>
            <div className="device">
              <img
                src={require(`../../../Assets/icons/devices/Desktop.png`)}
                alt=""
              />
              <img
                src={require(`../../../Assets/icons/brand/Asus.png`)}
                alt=""
              />
              <img src={require(`../../../Assets/icons/os/BEO.png`)} alt="" />
              <img
                src={require(`../../../Assets/icons/browsers/AA.png`)}
                alt=""
              />
            </div>
            <div className="dateOfVisit">
              <h5>{item.date}</h5>
            </div>
          </div>
          <div className="visit">
            <h5>Total Actions: {item.Metrics.action}</h5>
            <h5>Total visit: {item.Metrics.visit}</h5>
            <h5>Unique_visit: {item.Metrics.uniqueVisit}</h5>
          </div>
        </div>
      );
    });
  }
  return (
    <div className="Device">
      <div className="Card">
        <div className="Device-Title">
          <h3>Visitor Log</h3>
        </div>
      </div>
      <div className="data-container">
        <div className="data">
          <div className="containerForVisitorLog">{Datas}</div>
        </div>
        <div className="table-footer">
        <PaginationControl
          page={page}
          between={4}
          total={250}
          limit={20}
          changePage={(page) => {
            setPage(page);
            console.log(page);
          }}
          ellipsis={1}
        />
        </div>
      </div>
    </div>
  );
};

export default VisitorLog;
