import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { v4 as uuid } from "uuid";
import axios from "../api/axios";
const LOGIN_URL = "/api/login";
export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const userRef = useRef();
  useEffect(() => {
    userRef.current.focus();
  }, []);
  const SetNull = () => {
    setError("");
  };
  const isEmailValid = (input) => {
    // Email regex matched against standard email format
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email && !password) {
      setError("Email and Password are required");
      return;
    } else if (!password) {
      setError("password are required");
      return;
    } else if (!email) {
      setError("Email are required");
      return;
    }
    if (!isEmailValid(email)) {
      setError("Please enter a valid email address");
      return;
    }
    const token = uuid();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password, token }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = {
        token: response.data.token,
        name: response.data.username,
        id: response.data.id
      };
      if (response.data.status === 200) {
        const datas = new URLSearchParams(data).toString();
        // window.location.href = `http://dashboard.localhost:3000/?${datas}`;
        window.location.href = `https://dashboard.earaldtradinget.com/?${datas}`;
      } else if (response.data.status === 400) {
        setError("Invalid email or password");
      } else if (response.data.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Login Failed");
      }
      setEmail("");
      setPassword("");
    } catch (err) {
      if (err) {
        setError("No Server Response");
      }
    }
  };

  return (
    <section className="try">
      <div className="Back-login"></div>
      <div className="login">
        <div className="login-contents">
          <div className="login-cont">
            <div>
              <NavLink className="logo" to="/">
                <img src={require(`../Assets/icons/icon.png`)} alt="Tracker Logo" />
                <h1>Tracker</h1>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="form-cont">
            <div className="newusr">
              <h1>
                New user?{" "}
                <span>
                  <NavLink className="span" to="/registration">
                    Signup
                  </NavLink>
                </span>
              </h1>
            </div>
            <div>
              {error && (
                <span className="errmsg" aria-live="assertive">
                  {error}
                </span>
              )}
              <br />
              <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
                onFocus={SetNull}
                required
              />
              <br />
              <input
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="password"
                onFocus={SetNull}
                required
              />
              <br />
              <button onClick={handleSubmit} className="btn-login">
                Login
              </button>
            </div>
            <NavLink className="forget" to="/ForgotPassword">
              <h4>Forget Password?</h4>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
