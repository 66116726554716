import React, { useEffect, useState } from "react";
import PageVisit from "../components/PageVisit/PageVisit";
import PerformanceLine from "../components/PageVisit/PerformanceLine";
import Preloader from "../inc/Preloader";
const PageView = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {loading ? (
        <div className="preloader">
          <Preloader />
        </div>
      ) : (
        <div className="Page-card">
          <PerformanceLine />
          <PageVisit />
        </div>
      )}
    </>
  );
};
export default PageView;
