import React from "react";
import Header from "../LandingPage/layout/Header";
import Footer from "../LandingPage/layout/Footer";
import "../LandingPage/css/style.css";
import Map from "../Assets/images/map.svg";
import Graph2 from "../Assets/images/Graph2.svg";
import PieChart2 from "../Assets/images/PieChart2.svg";
import Desktop from "../Assets/images/Device.svg";

const Services = () => {
  return (
    <section>
      <Header />
      <div className="Help-page-body">
        <div className="help-page-header Step">
          <h1>Services of Liyu tracker</h1>
          <br />
        </div>
        <div className="help-page-header3">
          <h2>Categorized Location</h2>
          <br />
        </div>
        <div className="Service-one">
          <div className="left">
            <p>
              Categorized Location Service provides detailed information about
              the geographic location of website visitors. This allows website
              owners to gain insights into where their visitors are coming from
              and tailor their marketing efforts accordingly. The Categorized
              Location Service breaks down website visitors into categories
              based on their geographic location, making it easy to see where
              visitors are coming from at a glance. It also provides detailed
              information about IP addresses, making it easier to identify
              potential spam or fraudulent activity. This feature is
              particularly useful for websites with global reach, as it offers
              insights into regional trends and helps website owners to tailor
              their content to specific audiences.
            </p>
          </div>
          <div className="right">
            <div id="chart-container">
              <img src={Map} alt="Graph" />
            </div>
          </div>
        </div>
        <div className="help-page-header2">
          <h2>Device Identification and Analysis</h2>
          <br />
        </div>
        <div className="Service-two">
          <div className="left">
            <img src={Desktop} alt="Graph" />
          </div>

          <div className="right">
            <p>
              This Service is particularly useful for website owners who want to
              optimize their website for different devices such as desktop
              computers, tablets, and smartphones. With Liyu device tracking,
              website owners can gain insights into the types of devices their
              visitors are using to access their website. This information can
              be used to optimize the website's design and layout for different
              devices. For example, if a significant portion of website visitors
              are using smartphones, the website owner may want to ensure that
              the website is optimized for mobile devices by using a responsive
              design that adjusts to different screen sizes.
            </p>
          </div>
        </div>
        <div className="help-page-header3">
          <h2>Page Performance</h2>
        </div>
        <div className="Service-three">
          <div className="left">
            <p>
              Page Performance Monitoring service, which allows website owners
              to measure and analyze the speed and performance of their web
              pages. This service tracks key metrics like page-load time,
              time-to-first-byte (TTFB), network latency, and JavaScript
              execution time, providing website administrators with a clear
              picture of how quickly their site is loading and how responsive it
              is. By monitoring page performance, website owners can identify
              areas where their website may be running slowly or experiencing
              issues. With this information, they can then take steps to
              optimize the site and improve its performance, resulting in faster
              loading times and a better user experience for visitors. Liyu
              Analytics' Page Performance Monitoring service also allows website
              owners to set up alerts and notifications to notify them if their
              site's performance drops below a certain threshold or if there are
              any issues that need to be addressed.
            </p>
          </div>
          <div className="right">
            <img src={PieChart2} alt="" />
          </div>
        </div>
        <div className="help-page-header2">
          <h2>Softwares</h2>
        </div>
        <div className="Service-four">
          <div className="left">
            <img src={Graph2} alt="" />
          </div>
          <div className="right">
            <p>
              By monitoring the different software used by visitors to access a
              website, Liyu Analyticso can provide detailed insights into user
              behavior patterns and preferences. For example, if a website owner
              noticed that a significant percentage of their traffic is using
              outdated or unsupported browsers, they could use this information
              to improve the user experience for these visitors by optimizing
              their site for these browsers. On the other hand, if the majority
              of traffic is using mobile devices, optimizing for mobile
              responsiveness may be more important. In addition to providing
              insight into the types of software used by visitors, Liyu
              Analyticso also offers a range of analytics tools and features to
              help website owners monitor and optimize their site's performance.
              These tools can help site owners improve site speed, enhance user
              engagement, and drive conversions, among other important metrics.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Services;
