import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleGroup,
  faFileLines,
  faPerson,
  faBullseye,
} from "@fortawesome/free-solid-svg-icons";
import axios from "../../../api/axios";
const DASHBOARD_URL = "/api/dashboard";
const TotalCards = () => {
  const [totalVisit, setTotalVisit] = useState();
  const [totalAction, setTotalAction] = useState();
  const [totalUnuqe, setTotalUnuqe] = useState();
  const [totalPageView, setTotalPageView] = useState();
  useEffect(() => {
    const IntervalID = setInterval(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const response = axios
        .get(DASHBOARD_URL, {
          params: {
            token: searchParams.get("token"),
            date: searchParams.get("date"),
            id: searchParams.get("id"),
          },
        })
        .then(function (response) {
          setTotalVisit(response.data.TotalVisitor);
          setTotalAction(response.data.TotalActions);
          setTotalUnuqe(response.data.totalUniqueVisitors);
          setTotalPageView(response.data.totalPageView);
        })
        .catch(function (err) {});
      }, 3000);
      return () => clearInterval(IntervalID);
  }, []);
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="container-dash">
            <div className="dash">
              <div className="data">
                <h3>{totalVisit ? totalVisit : 0}</h3>
              </div>
              <div className="data titles">
                <div>
                  <span>
                    <FontAwesomeIcon
                      className="dash-icon"
                      icon={faPeopleGroup}
                    />
                  </span>
                  <h3>Total Visitor</h3>
                </div>
              </div>
            </div>
            <div className="dash">
              <div className="data">
                <h3>{totalAction ? totalAction : 0}</h3>
              </div>
              <div className="data titles">
                <div>
                  <span>
                    <FontAwesomeIcon className="dash-icon" icon={faBullseye} />{" "}
                  </span>
                  <h3>Total Action</h3>
                </div>
              </div>
            </div>
            <div className="dash">
              <div className="data">
                <h3>{totalUnuqe ? totalUnuqe : 0}</h3>
              </div>
              <div className="data titles">
                <div>
                  <span>
                    <FontAwesomeIcon className="dash-icon" icon={faPerson} />{" "}
                  </span>
                  <h3>Unique Visitor</h3>
                </div>
              </div>
            </div>
            <div className="dash">
              <div className="data">
                <h3>{totalPageView ? totalPageView : 0}</h3>
              </div>
              <div className="data titles">
                <div>
                  <span>
                    <FontAwesomeIcon className="dash-icon" icon={faFileLines} />
                  </span>
                  <h3>Total PageView</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalCards;
