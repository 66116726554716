import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { PaginationControl } from "react-bootstrap-pagination-control";
const POPULAR_URL = "/api/popularPage";
const PopularPages = () => {
  const [PopularPage, setPopularPage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(POPULAR_URL, {
        params: {
          token: searchParams.get("token"),
          date: searchParams.get("date"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setPopularPage(response.data);
        setLoading(false);
      })
      .catch(function (err) {});
  }, []);
  var Datas = "";
  if (loading) {
    Datas = <snap>Loading...</snap>;
  } else {
    Datas = PopularPage.map((item) => {
      return (
        <tr>
          <td>{item.pageUrl}</td>
          <td>{item.pageView}</td>
        </tr>
      );
    });
  }
  return (
    <div className="items">
      <div className="Card">
        <div className="Device-Title">
          <h3>Popular page(#7 days)</h3>
        </div>
        <div className="data-container">
          <div className="data">
            <table cellSpacing={0} cellPadding={0} className="data-table">
              <thead>
                <tr>
                  <th>Url</th>
                  <th>Visits</th>
                </tr>
              </thead>
              <tbody>
              {Datas}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="table-footer">
      <PaginationControl
          page={page}
          between={4}
          total={250}
          limit={20}
          changePage={(page) => {
            setPage(page);
            console.log(page);
          }}
          ellipsis={1}
        />
      </div>
    </div>
  );
};

export default PopularPages;
