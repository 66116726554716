import React, { useEffect, useState } from "react";
import "../style/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCircleInfo,
  faGear,
  faSignOutAlt,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import axios from "../../api/axios";
const NOTIFICATION_URL = "/api/notification";
// const VISIT_URL = "/api/hasvisit";
const Header = ({ children }) => {
  const [showHelp, setShowHelp] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [toogle, setToogle] = useState();
  const [isClickedBell, setIsClickedBell] = useState(false);
  const [isClickedHelp, setIsClickedHelp] = useState(false);
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(true);
  // const [isHasVisit, setIsHasVisit] = useState(true);
  const toggleNotification = () => {
    setShowNotification(!showNotification);
    setIsClickedBell(!isClickedBell);
  };
  // useEffect(() => {
  //     const response = axios
  //       .get(VISIT_URL, {})
  //       .then(function (response) {
  //         setIsHasVisit(!isHasVisit);
  //       })
  //       .catch(function (err) {});
  // }, []);
  const toggleHelp = () => {
    setShowHelp(!showHelp);
    setIsClickedHelp(!isClickedHelp);
  };

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setToogle(searchParams.get("date"));
  }, []);

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("date", toogle || "1 month");

  window.history.pushState(
    {},
    "",
    decodeURIComponent(`${window.location.pathname}?${searchParams}`)
  );

  const toggleClass = (Data) => () => {
    setToogle(Data);
  };

  const handleLogout = () => {
    // perform logout actions, such as clearing session data and redirecting to login page
    // console.log("Logging out...");
    window.location.href = "http://localhost:3000/";
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const profileImg = document.querySelector(".profile-img");
      if (profileImg && !profileImg.contains(event.target)) {
        setShowProfile(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  //click outside help pop up
  useEffect(() => {
    const handleOutsideClick2 = (event) => {
      const helpModalContent = document.querySelector(".help-poper");
      if (helpModalContent && !helpModalContent.contains(event.target)) {
        setShowHelp(false);
        setIsClickedBell(false);
        setIsClickedHelp(false);
      }
    };
    document.addEventListener("click", handleOutsideClick2);
    return () => {
      document.removeEventListener("click", handleOutsideClick2);
    };
  }, []);

  // click outside notification pop up
  useEffect(() => {
    const handleOutsideClick3 = (event) => {
      const Notification = document.querySelector(".Notification");
      if (Notification && !Notification.contains(event.target)) {
        setShowNotification(false);
        setIsClickedBell(false);
        setIsClickedHelp(false);
      }
    };
    document.addEventListener("click", handleOutsideClick3);
    return () => {
      document.removeEventListener("click", handleOutsideClick3);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(NOTIFICATION_URL, {
        params: {
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setNotification(response.data);
        setLoading(false);
      })
      .catch(function (err) {});
  }, []);
  var Datas = "";
  if (loading) {
    Datas = <snap>Loading...</snap>;
  } else {
    Datas = notification.map((item) => {
      // console.log(item);
      return (
        <tr key={item}>
          <tr>
            <td> &nbsp; {item.text}</td>
          </tr>
        </tr>
      );
    });
  }
  // console.log(notification);
  // Help dropdown
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [showMinus1, setShowMinus1] = useState(false);
  const [showMinus2, setShowMinus2] = useState(false);
  const [showMinus3, setShowMinus3] = useState(false);
  const [showMinus4, setShowMinus4] = useState(false);
  const [showMinus5, setShowMinus5] = useState(false);
  const [showMinus6, setShowMinus6] = useState(false);
  const handleClick1 = () => {
    setIsOpen1(!isOpen1);
    setShowMinus1(!showMinus1);
  };
  const handleClick2 = () => {
    setIsOpen2(!isOpen2);
    setShowMinus2(!showMinus2);
  };
  const handleClick3 = () => {
    setIsOpen3(!isOpen3);
    setShowMinus3(!showMinus3);
  };
  const handleClick4 = () => {
    setIsOpen4(!isOpen4);
    setShowMinus4(!showMinus4);
  };
  const handleClick5 = () => {
    setIsOpen5(!isOpen5);
    setShowMinus5(!showMinus5);
  };
  const handleClick6 = () => {
    setIsOpen6(!isOpen6);
    setShowMinus6(!showMinus6);
  };
  return (
    <div className="All-left-content">
      <div className="Top-Header">
        <div className="Date-picker">
          <div
            className={toogle === "last 30min" ? "period active" : "period"}
            onClick={toggleClass("last 30min")}
          >
            last 30min
          </div>
          <div
            className={toogle === "last 24hr" ? "period active" : "period"}
            onClick={toggleClass("last 24hr")}
          >
            last 24hr
          </div>
          <div
            className={toogle === "last Week" ? "period active" : "period"}
            onClick={toggleClass("last Week")}
          >
            last Week
          </div>
          <div
            className={toogle === "1 month" ? "period active" : "period"}
            onClick={toggleClass("1 month")}
          >
            1 month
          </div>
        </div>
        <div className="right-header-content">
          <div className="right-cont">
            <div className="Notification">
              <button onClick={toggleNotification}>
                <FontAwesomeIcon
                  icon={faBell}
                  className={
                    isClickedBell ? "profile-icon-inverted" : "profile-icon"
                  }
                />
              </button>
              {showNotification && (
                <div className="pop-up-overlay">
                  <div className="pop-up"></div>
                </div>
              )}
            </div>
            <NavLink>
              <div className="help-poper">
                <button onClick={toggleHelp}>
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className={
                      isClickedHelp ? "profile-icon-inverted" : "profile-icon"
                    }
                  />
                </button>
                {showHelp && (
                  <div className="help-modal-content">
                    <div className="help-modal-header">
                      <h3 className="help-modal-title">Help Center</h3>
                    </div>
                    <div className="help-modal-body">
                      <p>
                        Welcome to our help center. How can we assist you today?
                      </p>
                      <div className="help-faq-container">
                        <div className="accordion">
                          <div className="accordion-item">
                            <div>
                              <button onClick={handleClick1}>
                                <FontAwesomeIcon
                                  className="accordion-icon"
                                  icon={showMinus1 ? faMinus : faPlus}
                                />
                                <span className="accordion-title">
                                  &nbsp; No information displaying on the
                                  dashboard
                                </span>
                              </button>
                              {isOpen1 && (
                                <p>
                                  Check if the tracking code is integrated
                                  correctly if that's not the problem make sure
                                  the URL entered on the addsite form and the
                                  URL for your website is identical
                                </p>
                              )}
                            </div>
                            <div>
                              <button onClick={handleClick2}>
                                <FontAwesomeIcon
                                  className="accordion-icon"
                                  icon={showMinus2 ? faMinus : faPlus}
                                />
                                <span className="accordion-title">
                                  &nbsp; Some analytics is not being displayed
                                </span>
                              </button>
                              {isOpen2 && (
                                <p>
                                  information starts to display on your
                                  dashboard once the tracking code is integrated
                                  on your website and traffic starts to flow to
                                  the website
                                </p>
                              )}
                            </div>
                            <div>
                              <button onClick={handleClick3}>
                                <FontAwesomeIcon
                                  className="accordion-icon"
                                  icon={showMinus3 ? faMinus : faPlus}
                                />
                                <span className="accordion-title">
                                  &nbsp; Is Liyu Tracker free to use?
                                </span>
                              </button>
                              {isOpen3 && (
                                <p>Liyu Tracker is completely free to use</p>
                              )}
                            </div>
                            <div>
                              <button onClick={handleClick4}>
                                <FontAwesomeIcon
                                  className="accordion-icon"
                                  icon={showMinus4 ? faMinus : faPlus}
                                />
                                <span className="accordion-title">
                                  &nbsp; How do I install Liyu Tracker?
                                </span>
                              </button>
                              {isOpen4 && (
                                <p>
                                  Follow the steps on the help page in order to
                                  integrate Liyu Tracker to your website
                                </p>
                              )}
                            </div>
                            <div>
                              <button onClick={handleClick5}>
                                <FontAwesomeIcon
                                  className="accordion-icon"
                                  icon={showMinus5 ? faMinus : faPlus}
                                />
                                <span className="accordion-title">
                                  &nbsp; How do I get help and support for Liyu
                                  Tracker?
                                </span>
                              </button>
                              {isOpen5 && (
                                <p>
                                  If you face any problems that isn't covered on
                                  the help page or FAQs use the contact form to
                                  communicate with developers and support team
                                </p>
                              )}
                            </div>
                            <div>
                              <button onClick={handleClick6}>
                                <FontAwesomeIcon
                                  className="accordion-icon"
                                  icon={showMinus6 ? faMinus : faPlus}
                                />
                                <span className="accordion-title">
                                  &nbsp; What is Liyu Analytics ?
                                </span>
                              </button>
                              {isOpen6 && (
                                <p>
                                  Liyu Tracker is a web analytic system that
                                  mainly does collect and store analytics, and
                                  data provide reports of the stored data
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </NavLink>
            {showNotification && (
              <div className="pop-up-overlay">
                <div className="pop-up">
                  <FontAwesomeIcon icon={faGear} className="profile-icon" />
                </div>
              </div>
            )}
            <div className="Notification">
              {showNotification && (
                <div className="pop-up-overlay">
                  <div className="pop-up">
                    <div className="pop-up-data-container ">
                      <div className="pop-up-data ">
                        <table
                          cellSpacing={5}
                          cellPadding={11}
                          className="pop-up-data-table"
                        >
                          <thead>
                            <h3 className="pop-up-header-text">
                              {" "}
                              Notifications
                            </h3>
                          </thead>
                          <tbody>{Datas}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="profile-img" onClick={toggleProfile}>
              {showProfile && (
                <div className="dropdown-card">
                  <div class="Profile_Container">
                    <div class="card">
                      <div class="top-section"></div>
                      <div class="avatar">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfgr4ilzw7qrG8O_ybZLWGo_qLJVy1vjwHXRzJois&s"
                          alt="User Picture"
                        />
                      </div>
                      <div className="Card_username">
                        <h5>USER ONE</h5>
                      </div>
                      <div className="Card_email">
                        <p>
                          <i>testmail@mail.com</i>
                        </p>
                      </div>
                      <div className="Card_site_name">
                        <p>Website</p>
                      </div>
                      <div className="Profile-icons">
                        <div className="Profile-icons-setting">
                          <FontAwesomeIcon icon={faGear} />
                        </div>
                        <div
                          className="Profile-icons-logout"
                          onClick={handleLogout} // add logout handler
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {isHasVisit ? (
        <div></div>
      ) : (
        <div className="clildren-class dash-errors">
          There is no visit yet or the tracking code is not integrated
          successfully, try refreshing the page{" "}
        </div>
      )} */}
      <div className="clildren-class">{children}</div>
      <div className="footerOfAllpage">
        <h4> Liyu Analytics Team &copy; All Right Reserved</h4>
      </div>
    </div>
  );
};

export default Header;
