import React, { useEffect, useState } from "react";
import VisitDay from "../components/Vsitor/VisitDay";
import VisitorLog from "../components/Vsitor/VisitorLog";
import Referrers from "../components/Vsitor/Referrers";
import Preloader from "../inc/Preloader";
const Visitor = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {loading ? (
        <div className="preloader">
          <Preloader />
        </div>
      ) : (
        <div className="Page-card">
          <VisitDay />
          <div className="card-visitor-cont">
            <div className="one">
              <VisitorLog />
            </div>
            <div className="one">
              <Referrers />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Visitor;
