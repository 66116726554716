import React, { Component } from "react";
import Header from "./layout/Header";
import Body  from "./layout/Body";
import Discription from "./layout/Discription";
import Partner from "./layout/Partner";
import Testimonial from "./layout/Testimonial";
import Footer from "./layout/Footer";
export default class Index extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Body />
        <Discription />
        <Partner />
        <Testimonial />
        <Footer />
      </React.Fragment>
    );
  }
}