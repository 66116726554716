import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const PERFORMANCE_URL = "/api/Performance";
const PerformanceLine = () => {
  const [Performance, setPerformance] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(PERFORMANCE_URL, {
        params: {
          token: searchParams.get("token"),
          date: searchParams.get("date"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setPerformance(response.data);
        setLoading(false);
      })
      .catch(function (err) {});
  }, []);

  const options = {
    maintainAspectRatio: false,
    // responsive: true,
  };
  const labels = Object.keys(Performance);
  console.log(labels.map((key) => Performance[key].NetworkTime));
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Network Time",
        data: labels.map((key) => Performance[key].NetworkTime),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "Server Time",
        data: labels.map((key) => Performance[key].serverTime),
        fill: false,
        borderColor: "rgb(192, 75, 192)",
        tension: 0.1,
      },
      {
        label: "Transfer Time",
        data: labels.map((key) => Performance[key].TransferTime),
        fill: false,
        borderColor: "rgb(192, 192, 75)",
        tension: 0.1,
      },
      {
        label: "DOM Completion",
        data: labels.map((key) => Performance[key].DOMCompletion),
        fill: false,
        borderColor: "rgb(75, 75, 192)",
        tension: 0.1,
      },
      {
        label: "Page Load Time",
        data: labels.map((key) => Performance[key].PageLoadTime),
        fill: false,
        borderColor: "rgb(192, 75, 75)",
        tension: 0.1,
      },
    ],
  };
  return (
    <div className="one">
      <div className="Device-Title">
        <h3>Page Performance</h3>
      </div>
      <div className="data-container-line">
        <div className="data-line">
          <Line options={options} data={data} className="line-chart" />
        </div>
      </div>
    </div>
  );
};

export default PerformanceLine;
