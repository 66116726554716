import React from "react";
import "../style/style.css";

function Preloader() {
  return (
    <div class="Preloader">
      <hr />
      <hr />
      <hr />
      <hr />
    </div>
  );
}
export default Preloader;
