import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
const  Body = () => {
  const typedRef1 = useRef(null);
  const typedRef2 = useRef(null);
  useEffect(() => {
    const options1 = {
      strings: ['Uncover Potential', 'Unleash Analytics ', 'Amplify your Impact','Accelerate Growth','Analyse Your Traffic'],
      typeSpeed: 50,
      backSpeed: 50,
      smartBackspace: true,
    };
    const options2 = {
      strings: ['Start Tracking'],
      typeSpeed: 10,
      backSpeed: 10,
      smartBackspace: true,
    };
    const typed1 = new Typed(typedRef1.current, options1);
    const typed2 = new Typed(typedRef2.current, options2);
    return () => {
      typed1.destroy();
      typed2.destroy();
    };
  }, []);
  return (
    <section className="body">
      <div class="backimg">
        <div className="front">
          <div className="Enter-site-home">
            <h1 className="with1">With<span className="header-yellow">Liyu Analytics</span> </h1>
            <h1 className="landing_typed"><span ref={typedRef1}></span></h1>
            <button> <span ref={typedRef2}></span> </button>
          </div>
        </div>
        <div className="bottomforthefront"> </div>
      </div>
    </section>
  );
}
export default Body ;
