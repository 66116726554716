import React, { useEffect, useState } from "react";
import "../../AnalyticsDashboard/style/style.css";
import axios from "../../api/axios";
import Preloader from "../inc/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
const PROFILE_URL = "/api/profile";
const Settings = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [editfirstName, setEditFirstName] = useState("");
  const [editlastName, setEditLastName] = useState("");
  const [editemail, setEditEmail] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(PROFILE_URL, {
        params: {
          token: searchParams.get("token"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setFirstName(response.data.firstname);
        setLastName(response.data.lastname);
        setWebsiteName(response.data.websiteName);
        setEmail(response.data.email);
      })
      .catch(function (err) {});
  }, []);
  const handleUpdate = () => {
    let valid = true;
    if (!firstName) {
      setFirstNameError("Please enter your first name");
      valid = false;
    } else if (!/^[a-zA-Z]+$/.test(firstName)) {
      setFirstNameError("First name must only contain letters");
      valid = false;
    } else {
      setFirstNameError("");
    }
    if (!lastName) {
      setLastNameError("Please enter your last name");
      valid = false;
    } else if (!/^[a-zA-Z]+$/.test(lastName)) {
      setLastNameError("Last name must only contain letters");
      valid = false;
    } else {
      setLastNameError("");
    }
    if (!email) {
      setEmailError("Please enter your email address");
      valid = false;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailError("Invalid email address");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!password) {
      setPasswordError("Please enter a password");
      valid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      // Handle form submission
    }
  };

  return (
    <>
    {loading ? (
      <div className="preloader">
        <Preloader />
      </div>
    ) : (
    <div className="settings-body">
      <div className="try">
        <div className="avatar">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfgr4ilzw7qrG8O_ybZLWGo_qLJVy1vjwHXRzJois&s"
            alt="User Picture"
          />
        </div>
        <label  className="upload-picture">
  <input 
    type="file" />     
</label>
        <h1 className="settings-username">
          {firstName && lastName ? firstName + " " + lastName : "Liyu User"}
        </h1>
        <h4 className="settings-user-email">
          {email ? email : "liyuuser@gmail.com"}
        </h4>
        <h2>Edit Profile</h2>
        <div className="first-last-name-input">
          <br />
          <h5>First Name:</h5>
          <input
            type="text"
            value={editfirstName ? editfirstName : firstName}
            onChange={(e) => setEditFirstName(e.target.value)}
          />{" "}
          <br />
          {firstNameError && (
            <span style={{ color: "red" }}>{firstNameError}</span>
          )}
          <hr />
          <br />
          <h5>Last Name:</h5>
          <input
            type="text"
            value={editlastName ? editlastName : lastName}
            onChange={(e) => setEditLastName(e.target.value)}
          />
          <br />
          {lastNameError && (
            <span style={{ color: "red" }}>{lastNameError}</span>
          )}
          <hr />
          <br />
        </div>
        <h5>Website Name</h5>
        <input
          type="text"
          value={websiteName}
          onChange={(e) => setWebsiteName(e.target.value)}
        />
        <hr />
        <br />
        <h5>Email Address:</h5>
        <input
          type="email"
          value={editemail ? editemail : email}
          onChange={(e) => setEditEmail(e.target.value)}
        />
        <br />
        {emailError && <span style={{ color: "red" }}>{emailError}</span>}
        <hr />
        <br />
        <h5>Phone Number:</h5>
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <hr />
        <br />
        <h5>Password:</h5>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        {passwordError && <span style={{ color: "red" }}>{passwordError}</span>}
        <hr />
        <br />
        <button onClick={handleUpdate}>Update</button>
      </div>
    </div>
        )}
        </>
  );
};

export default Settings;
