import './App.css';
import React from 'react';
import { getApp } from './Config/Route';
import { BrowserRouter } from 'react-router-dom';
function App() {
  const Currentapp = getApp();
  return (
    <React.StrictMode>
    <BrowserRouter>
      <Currentapp />
    </BrowserRouter>
  </React.StrictMode>
  );
}

export default App;
