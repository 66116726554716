import React from "react";
import earald from "../../Assets/partners/Earald.jpg";
import nibret from "../../Assets/partners/nibret.jpg";
import erq from "../../Assets/partners/erq.png";
import creativ from "../../Assets/partners/creativehub.jpeg";

const Partner = () => {
  return (
    <section id="#partners" className="partenerts-container">
      <h1>Partners</h1>
      <div className="partners">
        <div className="partner-cont">
          <div className="box">
            <img src={earald} alt="Earald Ict " />
          </div>
          <div className="box">
            <img src={nibret} alt="Nibret" />
          </div>
          <div className="box">
            <img src={erq} alt="erq maed" />
          </div>
          <div className="box">
            <img src={creativ} alt="creative hub" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;
