import React, { useState } from "react";
import axios from "../api/axios";
import { NavLink } from "react-router-dom";

const FORGOT_PASSWORD_URL = "/api/forgot-password";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        FORGOT_PASSWORD_URL,
        JSON.stringify({ email }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
      setSuccessMessage(response.data.message);
      setErrorMessage("");
    } catch (err) {
      if (!err.response) {
        setErrorMessage("No Server Error");
      } else if (err.response.status === 400) {
        setErrorMessage("Email is required");
      } else if (err.response.status === 404) {
        setErrorMessage("Email not found");
      } else {
        setErrorMessage("Failed to send email");
      }
      setSuccessMessage("");
    }
  };

  return (
    <section className="try">
      <div className="Back-login"> </div>
      <div className="login">
        <div className="login-contents">
          <div className="login-cont">
            <div>
            <NavLink className="logo" to="/">
                <img src={require(`../Assets/icons/icon.png`)} alt="Tracker Logo" />
                <h1>Tracker</h1>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="form-cont">
            <h1>Reset your password</h1>
            <form onSubmit={handleForgotPassword}>
              {successMessage && (
                <p className="success-msg" aria-live="assertive">
                  {successMessage}
                </p>
              )}
              {errorMessage && (
                <p className="error-msg" aria-live="assertive">
                  {errorMessage}
                </p>
              )}
              <input
                type="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
                required
              />
              <br />
              <button className="btn-login">Reset Password</button>
            </form>
            <NavLink to="/login">
              <h4 className="back-to-login">Back to Login</h4>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
