import React, { useEffect, useState } from "react";
import Preloader from "../inc/Preloader";
import Continent from "../components/Location/Continent";
import Country from "../components/Location/Country";
import Region from "../components/Location/Region";
import City from "../components/Location/City";
const Location = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      {loading ? (
        <div className="preloader">
          <Preloader />
        </div>
      ) : (
        <div className="card-cont">
          <div className="one">
            <Continent />
          </div>
          <div className="one">
            <Country />
          </div>
          <div className="one">
            <Region />
          </div>
          <div className="one">
            <City />
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
