import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { PaginationControl } from "react-bootstrap-pagination-control";
const REALTIME_URL = "/api/realTime";
const RealTime = () => {
  const [realTime, setRealTime] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {

      const searchParams = new URLSearchParams(window.location.search);
      const response = axios
        .get(REALTIME_URL, {
          params: {
            token: searchParams.get("token"),
            date: searchParams.get("date"),
            id: searchParams.get("id"),
          },
        })
        .then(function (response) {
          setRealTime(response.data);
          setLoading(false);
        })
        .catch(function (err) {});

  }, []);
  var Datas = "";
  if (loading) {
    Datas = <snap>Loading...</snap>;
  } else {
    Datas = realTime.map((item) => {
      return (
        <div className="ActualData">
          <div>
            <h5>visitId: {item.visitId}</h5>
            <h5>visitorId: {item.visitorID}</h5>
            <img
              src={require(`../../../Assets/icons/devices/Desktop.png`)}
              alt=""
            />
            <img src={require(`../../../Assets/icons/brand/Asus.png`)} alt="" />
            <img src={require(`../../../Assets/icons/os/BEO.png`)} alt="" />
            <img
              src={require(`../../../Assets/icons/browsers/AA.png`)}
              alt=""
            />
          </div>
          <div>
            <h5>{item.Referrer}</h5>
            <h5>{item.time}</h5>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="items">
      <div className="Card">
        <div className="Device-Title">
          <h3>RealTime</h3>
        </div>
        <div className="RealTimedata-container">
          <div className="RealTimeDataview">{Datas}</div>
        </div>
      </div>
      <div className="table-footer">
        <PaginationControl
          page={page}
          between={4}
          total={250}
          limit={20}
          changePage={(page) => {
            setPage(page);
            // console.log(page);
          }}
          ellipsis={1}
        />
      </div>
    </div>
  );
};

export default RealTime;
