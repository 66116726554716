import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { PaginationControl } from "react-bootstrap-pagination-control";
const PAGE_URL = "/api/pageview";
const PageVisit = () => {
  const [PageVisit, setPageVisit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(PAGE_URL, {
        params: {
          token: searchParams.get("token"),
          date: searchParams.get("date"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setPageVisit(response.data);
        setLoading(false);
      })
      .catch(function (err) {});
  }, []);
  var Datas = "";
  if (loading) {
    Datas = <snap>Loading...</snap>;
  } else {
    Datas = PageVisit.map((item) => {
      return (
        <tr key={item}>
          <td>{item.pageUrl}</td>
          <td>{item.title}</td>
          <td>{item.pageView}</td>
          <td>{item.PageLoadTime / 1000 + "s"}</td>
        </tr>
      );
    });
  }
  return (
    <div className="one">
      <div className="Device-Title">
        <h3>PageView</h3>
      </div>

      <div className="data-container">
        <div className="data">
          <table cellSpacing={0} cellPadding={0} className="data-table">
            <thead>
              <tr>
                <th>url</th>
                <th>title</th>
                <th>PageView</th>
                <th>PageLoadTime</th>
              </tr>
            </thead>
            <tbody>{Datas}</tbody>
          </table>
        </div>
        <div className="table-footer">
          <PaginationControl
            page={page}
            between={4}
            total={250}
            limit={20}
            changePage={(page) => {
              setPage(page);
              console.log(page);
            }}
            ellipsis={1}
          />
        </div>
      </div>
    </div>
  );
};

export default PageVisit;
