import React, { useLayoutEffect, useRef, useState } from 'react';
import '../css/style.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faRightToBracket } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const links = [
    {
      path: '/',
      name: 'Home',
    },
    {
      path: '/',
      name: 'Partners',
    },
    {
      path: '/Services',
      name: 'Services',
    },
    {
      path: '/',
      name: 'About us',
    },
    {
      path: '/HelpPage',
      name: 'Help?',
    },
  ];

  const dropdownLinks = [
    
    {
      path: '/',
      name: 'Home',
    },
    {
      path: '/',
      name: 'Partners',
    },
    {
      path: '/Services',
      name: 'Services',
    },
    {
      path: '/',
      name: 'About us',
    },
    {
      path: '/HelpPage',
      name: 'Help?',
    },
  ];
  const stickyHeader = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  useLayoutEffect(() => {
    const mainHeader = document.getElementById('Navigation-bar');
    let fixedTop = stickyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        mainHeader.classList.add('sticky');
      } else {
        mainHeader.classList.remove('sticky');
      }
    };
    window.addEventListener('scroll', fixedHeader);
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <header id='Navigation-bar' ref={stickyHeader}>
      <div className='Header-container'>
        <div className='logo-header'>
        <button className='hamburger-menu menu-one' onClick={toggleDropdown}>
          <FontAwesomeIcon icon={showDropdown ? '' : faBars} size='2x' />
        </button>
          <a className='menu-two'><h1>Liyu</h1></a> &nbsp;
        <div className='hamburger-menu menu-three'>
        <a href="/login"><FontAwesomeIcon icon={faRightToBracket} size='2x' href='/login'/></a>
        </div>
        </div>
        {showDropdown ? (
          <div className='dropdown-menu'>
            <nav className='navigation'>
              <ul>
                {dropdownLinks.map((link) => (
                  <NavLink className='navLink' to={link.path} key={link.path}>
                    <span>{link.name}</span>
                  </NavLink>
                ))}
              </ul>
            </nav>
          </div>
        ) : (
          <div className='Navigation-content'>
            <nav className='navigation'>
              <ul>
                {links.map((link) => (
                  <NavLink className='navLink' to={link.path} key={link.path}>
                    <span>{link.name}</span>
                  </NavLink>
                ))}
                <NavLink to='/login' className='login-btn'>
                  Login
                </NavLink>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;