import React, { useState } from 'react';
import "../AnalyticsDashboard/style/style.css";
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 

function TokenPage({ text }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = (event) => {
    event.preventDefault();
    const toryElement = document.querySelector('.tory');
    if (toryElement) {
      navigator.clipboard.writeText(toryElement.textContent);
      setIsCopied(true);
    }
  };

  return (
    <section className="Token">
      <div className="Token_page">
        <form method="post">
          <div className='done_checkmark'>
          <FontAwesomeIcon icon={faCircleCheck} />
            <h5>&nbsp; Addsite Completed</h5>
          </div>

          <fieldset>
            <legend>
              <br />
              <b>Integrate your website</b>
              <br />
              <p>To track your web traffic with Liyu Tracker you need to make sure some extra code is added to each of your webpages</p>

              <br />

              <b>Copy JavaScript Tracking Code</b>

              <p>Make sure this code is on every page of your website. We recommend pasting immediately before the closing &lt;/head&gt; tag.</p>

              <br />

              <label><div className='card-front'>
                <p className='tory'>
                  <div className='Copy_button'>
                    <button onClick={handleCopyClick}>
                      {isCopied ? 'Copied!' : 'Copy'}
                    </button>
                  </div>
                  <br />
                  <p>
                  &lt;head&gt;<br />
        &lt;meta charset=&quot;UTF-8&quot;&gt;<br />
        &lt;meta http-equiv=&quot;X-UA-Compatible&quot; content=&quot;IE=edge&quot;&gt;<br />
        &lt;meta name=&quot;viewport&quot; content=&quot;width=device-width, initial-scale=1.0&quot;&gt;<br />
        &lt;title&gt;Document&lt;/title&gt;<br />
    &lt;/head&gt;  &lt;head&gt;<br />
        &lt;meta charset=&quot;UTF-8&quot;&gt;<br />
        &lt;meta http-equiv=&quot;X-UA-Compatible&quot; content=&quot;IE=edge&quot;&gt;<br />
        &lt;meta name=&quot;viewport&quot; content=&quot;width=device-width, initial-scale=1.0&quot;&gt;<br />
        &lt;title&gt;Document&lt;/title&gt;<br />
    &lt;/head&gt;</p>
                  </p>
              </div>
              </label>
            </legend>
          </fieldset>

          <a href="/dashboard"></a><button type="button" className='Token__Button'>Done</button>
        </form>
      </div>
    </section>
  );
}

export default TokenPage;