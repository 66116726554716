import React from "react";
import earald from "../../Assets/partners/Earald.jpg";
import nibret from "../../Assets/partners/nibret.jpg";
import erq from "../../Assets/partners/erq.png";
import creativ from "../../Assets/partners/creativehub.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const links = [
    {
      path: "/",
      name: "Home",
    },
    {
      path: "/",
      name: "Partners",
    },
    {
      path: "/",
      name: "Services",
    },
    {
      path: "/",
      name: "About us",
    },
    {
      path: "/",
      name: "Help",
    },
    {
      path: "/",
      name: "Locate us",
    },
  ];
  const partners = [
    {
      path: "/",
      name: "Earald Ict",
      src: earald,
    },
    {
      path: "/",
      name: "Nibret",
      src: nibret,
    },
    {
      path: "/",
      name: "erq maed",
      src: erq,
    },
    {
      path: "/",
      name: "creative hub",
      src: creativ,
    },
  ];
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4 className="important_links">Important Links</h4>
            <ul>
              {links.map((link) => (
                <NavLink to={link.path} className="list">
                  
                    <span>{link.name}</span>
                
                </NavLink>
              ))}
            </ul>
          </div>
          <div className="footer-col">
            <h4>Address</h4>
            <ul>
              <NavLink to="/" className="add">
                  <FontAwesomeIcon className="address-icon"  icon={faLocationDot} />
                  <span> Mexico Addis Ababa Ethiopia </span>
              </NavLink>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Partners</h4>
            <div className="partners">
              <ul>
                {partners.map((link) => (
                  <NavLink to={link.path}>
                    <li>
                      <img src={link.src} alt={link.name} />
                    </li>
                  </NavLink>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <NavLink to="/" className="social">
                <FontAwesomeIcon className="social-icon" icon={faFacebookF} />
              </NavLink>
              <NavLink to="/" className="social">
                <FontAwesomeIcon className="social-icon" icon={faTwitter} />
              </NavLink>
              <NavLink to="/" className="social">
                <FontAwesomeIcon className="social-icon" icon={faInstagram} />
              </NavLink>
              <NavLink to="/"  className="social">
                <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
