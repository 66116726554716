import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Error from "../pages/Error";
import Index from "../LandingPage/Index";
import Sidebar from "../AnalyticsDashboard/inc/Sidebar";
import Dashboard from "../AnalyticsDashboard/Pages/Dashboard";
import Device from "../AnalyticsDashboard/Pages/Device";
import Location from "../AnalyticsDashboard/Pages/Location";
import Software from "../AnalyticsDashboard/Pages/Software";
import Header from "../AnalyticsDashboard/inc/Header";
import Addsite from "../LandingPage/Addsite";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import HelpPage from "../pages/HelpPage";
import PageView from "../AnalyticsDashboard/Pages/PageView";
import Visitor from "../AnalyticsDashboard/Pages/Visitor";
import Settings from "../AnalyticsDashboard/Pages/Settings";
import Services from "../pages/Services";
import TokenPage from "../LandingPage/TokenPage";
// import Gtest from "../pages/Gtest";
import RequireAuth from "../pages/RequireAuth";
// import HeaderIndex from "../LandingPage/layout/Header";
export const IndexRoutes = () => {
  let location = useLocation();
  return (
    <React.Fragment>
      {location.pathname === "/" ? <Index /> : <></>}
      <Routes primary={false}>
        {/* <HeaderIndex> */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/registration" element={<Register />} />
          <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
          <Route exact path="/TokenPage" element={<TokenPage />} />
          <Route exact path="/HelpPage" element={<HelpPage />} />
          <Route exact path="/Services" element={<Services />} />
          {/* <Route element={<RequireAuth />}> */}
          <Route exact path="/Addsite" element={<Addsite />} />
          <Route exact path="*" element={<Error />} /> 
          {/* </Route> */}
          {/* <Route exact path="Gtest" element={<Gtest />} /> */}
          {/* */}
        {/* </HeaderIndex> */}
      </Routes>
    </React.Fragment>
  );
};
export const SubRoutes = () => {
  return (
    <React.Fragment>
      <Sidebar>
        <Header>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/Device" element={<Device />} />
              <Route path="/Location" element={<Location />} />
              <Route path="/Software" element={<Software />} />
              <Route path="/PageView" element={<PageView />} />
              <Route path="/Visitor" element={<Visitor />} />
              <Route path="/Settings" element={<Settings />} />
              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
        </Header>
      </Sidebar>
    </React.Fragment>
  );
};
