import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { PaginationControl } from "react-bootstrap-pagination-control";
const COUNTRY_URL = "/api/Country";
const Country = () => {
  const [Country, setCountry] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(COUNTRY_URL, {
        params: {
          token: searchParams.get("token"),
          date: searchParams.get("date"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        setCountry(response.data);
        setLoading(false);
        console.log(response);
      })
      .catch(function (err) {});
  }, []);

  var Datas = "";
  if (loading) {
    Datas = <snap>Loading...</snap>;
  } else {

    Datas = Country.map((item) => {
      return (
        <tr key={item}>
          <td>
            <img
              src={require(`../../../Assets/icons/flags/${item.code.toLowerCase()}.png`)}
              alt={item.name}
            />
            <p>{item.name}</p>
          </td>
          <td>{item.visit}</td>
        </tr>
      );
    });
  }
  return (
    <div className="Location Device">
      <div className="Card">
        <div className="Device-Title">
          <h3>Country</h3>
        </div>
      </div>
      <div className="data-container">
        <div className="data">
          <table cellSpacing={0} cellPadding={0} className="data-table">
            <thead>
              <tr>
                <th>Country</th>
                <th>unique_visit</th>
              </tr>
            </thead>
            <tbody>{Datas}</tbody>
          </table>
        </div>
        <div className="table-footer">
          <PaginationControl
            page={page}
            between={4}
            total={250}
            limit={20}
            changePage={(page) => {
              setPage(page);
              console.log(page);
            }}
            ellipsis={1}
          />
        </div>
      </div>
    </div>
  );
};

export default Country;
