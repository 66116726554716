import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
const VISITORDAY_URL = "/api/visitorPerPage";
const VisitDay = () => {
  const [VisitorPerDay, setVisitorPerDay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const response = axios
      .get(VISITORDAY_URL, {
        params: {
          token: searchParams.get("token"),
          date: searchParams.get("date"),
          id: searchParams.get("id"),
        },
      })
      .then(function (response) {
        // console.log(response.data);
        setVisitorPerDay(response.data);
        setLoading(false);
      })
      .catch(function (err) {});
  }, [1000]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
  };
const labels = Object.keys(VisitorPerDay);
  const data = {
    labels : labels,
    datasets: [
      {
        label: 'Visit',
        data: labels.map(key => VisitorPerDay[key].visit),
        fill: true,
        borderColor: 'red',
      },
      {
        label: 'Unique Visit',
        data: labels.map(key => VisitorPerDay[key].unique_visit),
        fill: true,
        borderColor: 'green',
      },
      {
        label: 'Action',
        data: labels.map(key => VisitorPerDay[key].action),
        fill: true,
        borderColor: 'blue',
      },
    ],
  };

  return (
    <div className="one">
      <div className="Card">
        <div className="Device-Title">
          <h3>Visitor Per Days</h3>
        </div>
      </div>
      <div className="data-container-line">
        <div className="data-line">
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default VisitDay;
