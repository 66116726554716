import "../LandingPage/css/style.css";
import React, { useEffect, useRef, useState } from "react";
import Header from "../LandingPage/layout/Header";
import Footer from "../LandingPage/layout/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import axios from "../api/axios";
const COMMENT_URL = "/api/comment";
function HelpPage() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [showMinus1, setShowMinus1] = useState(false);
  const [showMinus2, setShowMinus2] = useState(false);
  const [showMinus3, setShowMinus3] = useState(false);
  const [showMinus4, setShowMinus4] = useState(false);
  const [showMinus5, setShowMinus5] = useState(false);
  const [showMinus6, setShowMinus6] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [id, setId] = useState();
  const userRef = useRef();
  const handleClick1 = () => {
    setIsOpen1(!isOpen1);
    setShowMinus1(!showMinus1);
  };
  const handleClick2 = () => {
    setIsOpen2(!isOpen2);
    setShowMinus2(!showMinus2);
  };
  const handleClick3 = () => {
    setIsOpen3(!isOpen3);
    setShowMinus3(!showMinus3);
  };
  const handleClick4 = () => {
    setIsOpen4(!isOpen4);
    setShowMinus4(!showMinus4);
  };
  const handleClick5 = () => {
    setIsOpen5(!isOpen5);
    setShowMinus5(!showMinus5);
  };
  const handleClick6 = () => {
    setIsOpen6(!isOpen6);
    setShowMinus6(!showMinus6);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setId(searchParams.get("id"));
  }, []);
  const isEmailValid = (input) => {
    // Email regex matched against standard email format
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
  };
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name && !email && !text) {
      setError("Please enter required information");
      return false;
    } else if (!name) {
      setError("Please enter your name");
      return false;
    } else if (!email) {
      setError("Please enter your email");
      return false;
    } else if (!text) {
      setError("Please enter your Feedback");
      return false;
    } else {
      return true;
    }
  };
  const submitComment = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (!isEmailValid(email)) {
      setError("Please enter a valid email address");
      return;
    }
    try {
      const response = await axios.post(
        COMMENT_URL,
        JSON.stringify({  name: name, email:email, text:text }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.status === 200) {
        setError("Thanks For Your Feedback");
      }else {
        setError("Failed");
      }
      setText("");
      setEmail("");
      setName("");
    } catch (err) {
   console.log(err);
        setError("No Server Response");
      
    }
  };
  return (
    <section>
      <Header />
      <div className="Help-page-body">
        <div className="help-page-header Step">
          <h1>Steps to integrate Liyu tracker to your Website</h1>
          <br />
        </div>
        <ul>
          <li>
            <div className="reveal">
              <p>
                <phase>STEP 1</phase>
                Register for Liyu tracker and follow registration steps
                <br />
                <p>
                  After finishing registration you'll be redirected to Addsite
                  page
                </p>
              </p>
            </div>
          </li>

          <li>
            <div className="reveal">
              <p>
                <phase>STEP 2</phase>
                Fill all the fields on the Addsite form and fill in your
                website's details. Be sure to enter the correct URL and
                description.
                <br />
                After filling the Addsite form you'll be redirected to a to a
                page that has tracking code
                <br />
              </p>
            </div>
          </li>
          <li>
            <div className="reveal">
              <p>
                <phase>STEP 3</phase>
                Copy the Liyu tracker tracking code: <br />
                After filling up Addsite, you will be given a unique tracking
                code. Copy this code to your clipboard.
              </p>
            </div>
          </li>
          <li>
            <div className="reveal">
              <p>
                <phase>STEP 4</phase>
                Add the tracking code to your site <br />
                Paste the tracking code into the &lt;head&gt; section of your
                website's HTML code, just before the closing &lt;/head &gt; tag.
              </p>
            </div>
          </li>
          <li>
            <div className="reveal">
              <p>
                <phase>STEP 5</phase>
                Refresh your page <br />
              </p>
            </div>
          </li>
          <li>
            <div className="reveal">
              <p>
                <phase>STEP 6</phase>
                Check your Liyu tracker dashboard <br />
                Tracking information of your website should appear on your Liyu
                Tracker dashboard
                <br />
              </p>
            </div>
          </li>
        </ul>
        <h1 className="help-page-header">Frequently Asked Questions</h1>

        <div class="help-card">
          <div className="help-faq-container">
            <div className="accordion">
              <div className="accordion-item">
                <div>
                  <button onClick={handleClick1}>
                    <FontAwesomeIcon
                      className="accordion-icon"
                      icon={showMinus1 ? faMinus : faPlus}
                    />
                    <span className="accordion-title">
                      &nbsp; No information displaying on the dashboard
                    </span>
                  </button>
                  {isOpen1 && (
                    <p>
                      Check if the tracking code is integrated correctly if
                      that's not the problem make sure the URL entered on the
                      addsite form and the URL for your website is identical
                    </p>
                  )}
                </div>
                <div>
                  <button onClick={handleClick2}>
                    <FontAwesomeIcon
                      className="accordion-icon"
                      icon={showMinus2 ? faMinus : faPlus}
                    />
                    <span className="accordion-title">
                      &nbsp; Some analytics is not being displayed
                    </span>
                  </button>
                  {isOpen2 && (
                    <p>
                      information starts to display on your dashboard once the
                      tracking code is integrated on your website and traffic
                      starts to flow to the website
                    </p>
                  )}
                </div>
                <div>
                  <button onClick={handleClick3}>
                    <FontAwesomeIcon
                      className="accordion-icon"
                      icon={showMinus3 ? faMinus : faPlus}
                    />
                    <span className="accordion-title">
                      &nbsp; Is Liyu Tracker free to use?
                    </span>
                  </button>
                  {isOpen3 && <p>Liyu Tracker is completely free to use</p>}
                </div>
                <div>
                  <button onClick={handleClick4}>
                    <FontAwesomeIcon
                      className="accordion-icon"
                      icon={showMinus4 ? faMinus : faPlus}
                    />
                    <span className="accordion-title">
                      &nbsp; How do I install Liyu Tracker?
                    </span>
                  </button>
                  {isOpen4 && (
                    <p>
                      Follow the steps on the help page in order to integrate
                      Liyu Tracker to your website
                    </p>
                  )}
                </div>
                <div>
                  <button onClick={handleClick5}>
                    <FontAwesomeIcon
                      className="accordion-icon"
                      icon={showMinus5 ? faMinus : faPlus}
                    />
                    <span className="accordion-title">
                      &nbsp; How do I get help and support for Liyu Tracker?
                    </span>
                  </button>
                  {isOpen5 && (
                    <p>
                      If you face any problems that isn't covered on the help
                      page or FAQs use the contact form to communicate with
                      developers and support team
                    </p>
                  )}
                </div>
                <div>
                  <button onClick={handleClick6}>
                    <FontAwesomeIcon
                      className="accordion-icon"
                      icon={showMinus6 ? faMinus : faPlus}
                    />
                    <span className="accordion-title">
                      &nbsp; What is Liyu Analytics ?
                    </span>
                  </button>
                  {isOpen6 && (
                    <p>
                      Liyu Tracker is a web analytic system that mainly does
                      collect and store analytics, and data provide reports of
                      the stored data
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="help-page-header">
          <h1 className="contact-header">
            Need Another help ?
            <br />
            Please contact the help team
          </h1>
          <br />
        </div>
        <div className="Help-contact">
          {error && (
            <span className="errmsg" aria-live="assertive">
              {error}
            </span>
          )}
          <div>
            <input
              name="name"
              type="text"
              className="feedback-input"
              ref={userRef}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
            <input
              name="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="feedback-input"
              placeholder="Email"
            />
            <textarea
              name="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="feedback-input"
              placeholder="Please Explain the difficulty you're facing on Liyu Tracker"
            ></textarea>
            <button
              onClick={submitComment}
              className="Help-contact-button"
              value="SUBMIT"
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default HelpPage;
