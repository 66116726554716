import React from "react";
import { Outlet} from "react-router-dom";
const RequireAuth = () => {
  // If the user is not authenticated it will redirect to
  // the login page, otherwise it will redirect to the dashboard
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get("token")) {
    return <Outlet />;
  } else {
    window.location.href = "http://localhost:3000/login";
    // window.location.href = "https://liyu.earaldtradinget.com/login";
  }
};

export default RequireAuth;